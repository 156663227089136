import React, { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import { Router } from '@reach/router';

import { Route } from '../components/router';
import { InvestigarMasLayout } from '../layouts';
import { api } from '../services';

const InvestigarMasPage = props => {
  const [data, setData] = useState(null);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState('');
  const [showModal, setShowModal] = useState(false);
  const currentLang = useSelector(({ config }) => config.language);
  const stepStore = useSelector(store => store.step);
  const { fetchResearchMoreData } = api();

  // Array of task unlocks
  const unlockedTasks = stepStore.unlockedSteps
    .map(step => step.tasks)
    .reduce((obj, val) => [...obj, ...val], []);

  // Toggle modal
  const toggleModal = () => {
    setShowModal(!showModal);
  };

  useEffect(() => {
    let ignore = false;
    const fetchData = async ignore => {
      try {
        let response = null;
        response = await fetchResearchMoreData(props.id, currentLang);

        if (!ignore) {
          setData(response);
        }
      } catch (e) {
        setError(e.message);
      } finally {
        setLoading(false);
      }
    };
    fetchData(ignore);
    return () => {
      ignore = true;
    };
  }, [currentLang, props, fetchResearchMoreData]);

  return loading ? null : (
    <Router>
      <Route
        path="/tarea/:id/investigar-mas"
        component={InvestigarMasLayout}
        data={data}
        theme={data.content.theme}
        loading={loading}
        error={error}
        unlockedTasks={unlockedTasks}
        toggleModal={toggleModal}
      />
    </Router>
  );
};

export default InvestigarMasPage;
